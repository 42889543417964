import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SEO from "../components/seo";

import FeaturePararaph from '../components/feature-paragraph'

import ListItem from '../components/list-item';


const CookiePolicy = () => (
  <Layout>
    <SEO 
    	title="Cookie Policy"
    	description="Outlining TimeKeeper Cookie Policy and use of cookies."
    	 />
    <Navbar />
    <div className="relative py-16 bg-white overflow-hidden">
		<div className="flex flex-col items-center px-4 sm:px-6 lg:px-8">
		    <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
		      Cookie Policy
		    </h1>
		</div>
		<div className="mt-12 w-full prose lg:prose-xl text-gray-600 mx-auto">
			<p><b>BY CONTINUING TO USE THIS SITE YOU CONSENT TO THE USE OF COOKIES IN ACCORDANCE WITH OUR COOKIE POLICY</b></p>
			<p>Our website (and associated sub-domains) use cookies to distinguish you from other users of our website. This helps us to provide you with a reliable and positive experience when you browse our website (and associated sub-domains) and also allows us to improve the use and functionality of our website, and to gain a greater understanding of how our website, and its related tools and services, are used.</p>
			<p>A cookie is a small file of data that may be stored on your browser or the hard drive of your computer or mobile device, subject to your agreement.  The cookie allows the website to recognise the device of the user and store certain information about the user’s past actions and/or preferences.</p>
			<p>We use the following cookies:</p>

			<ul>
				<li><b>Strictly necessary cookies.</b> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</li>
				<li><b>Functional cookies.</b> These are used to recognise you when you return to our website. This enables us to personalise our content for you and remember your preferences.</li>
				<li><b>Performance cookies.</b> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the content displayed on it is more relevant to your interests.</li>
			</ul>


			<div className="overflow-x-auto">
				<table className="p-4"> 
					<tr className="border p-4">
			            <th>Cookie</th>
			            <th>Party</th>
			            <th>Purpose</th>
			            <th>Category</th>
			            <th>Domain</th>
			            <th>Expiration</th>
			        </tr>
			        <tr>
			            <td className="border p-4">utm_params</td>
			            <td className="border p-4">TimeKeeper</td>
			            <td className="border p-4">This cookie allows us to track what ad campaigns/social posts are leading to customer sign ups in circumstances where the user has visited our website with UTM parameters being specified.</td>
			            <td className="border p-4">Performance</td>
			            <td className="border p-4">*.timekeeper.co.uk</td>
			            <td className="border p-4">30 days</td>
			        </tr>
			        <tr>
			            <td className="border p-4">crisp_client*</td>
			            <td className="border p-4">Crisp.chat</td>
			            <td className="border p-4">This cookie allows users to avail of a chat function on our website.  See below policy for more detail <a href="https://help.crisp.chat/en/article/crisp-chatbox-cookie-ip-policy-1147xor"> here</a></td>
			            <td className="border p-4">Functional</td>
			            <td className="border p-4">*.timekeeper.co.uk</td>
			            <td className="border p-4">6 months 2 days 11 hours 59 minutes 58 seconds</td>
			        </tr>
			        <tr>
			            <td className="border p-4">rewardful.referral</td>
			            <td className="border p-4">GetRewardful.com</td>
			            <td className="border p-4">This cookie enables us to know when a user has been referred to us if this has been done through a user affiliate link. </td>
			            <td className="border p-4">Performance</td>
			            <td className="border p-4">*.timekeeper.co.uk</td>
			            <td className="border p-4">60 days</td>
			        </tr>
			        <tr>
			            <td className="border p-4">__stripe_mid</td>
			            <td className="border p-4">Stripe</td>
			            <td className="border p-4">This cookie is set by Stripe and is used to enable payment on our website in a manner that mitigates against the risk of fraud. </td>
			            <td className="border p-4">Necessary</td>
			            <td className="border p-4">app.timekeeper.co.uk</td>
			            <td className="border p-4">1 year</td>
			        </tr>
			        <tr>
			            <td className="border p-4">__stripe_sid</td>
			            <td className="border p-4">Stripe</td>
			            <td className="border p-4">This cookie is set by Stripe and is used to enable payment on our website in a manner that mitigates against the risk of fraud.</td>
			            <td className="border p-4">Necessary</td>
			            <td className="border p-4">app.timekeeper.co.uk</td>
			            <td className="border p-4">30 minutes</td>
			        </tr>
			        <tr>
			            <td className="border p-4">_GRECAPTCHA</td>
			            <td className="border p-4">Google</td>
			            <td className="border p-4">This cookie prevents spam bots from accessing our website.</td>
			            <td className="border p-4">Necessary</td>
			            <td className="border p-4">app.timekeeper.co.uk</td>
			            <td className="border p-4">Up to 6 months</td>
			        </tr>
			        <tr>
			            <td className="border p-4">VISITOR_INFO1_LIVE</td>
			            <td className="border p-4">YouTube</td>
			            <td className="border p-4">YouTube cookie to enable personalised recommendations on YouTube based on past views and searches.</td>
			            <td className="border p-4">Performance</td>
			            <td className="border p-4">*.timekeeper.co.uk</td>
			            <td className="border p-4">5 months 4 weeks 1 day 23 hours 59 minutes 58 seconds</td>
			        </tr>
			        <tr>
			            <td className="border p-4">YSC</td>
			            <td className="border p-4">YouTube</td>
			            <td className="border p-4">Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
			            <td className="border p-4">Performance</td>
			            <td className="border p-4">*.timekeeper.co.uk</td>
			            <td className="border p-4">Session</td>
			        </tr>
			        <tr>
			            <td className="border p-4">cookiefirst-consent</td>
			            <td className="border p-4">Necessary</td>
			            <td className="border p-4">This cookie saves your cookie preferences for this website. You can change these or withdraw your consent easily.</td>
			            <td className="border p-4">Necessary</td>
			            <td className="border p-4">timekeeper.co.uk</td>
			            <td className="border p-4">12 months</td>
			        </tr>
				</table>
			</div>
			<h3>Third Party Cookies</h3>
			<p>Please note that the following third parties also use cookies, over which are neccessary and have no control on their customisation.</p>
			<ul>
				<li>Stripe</li>
				<li>Google Recaptcha</li>
			</ul>
			<p>Further information about these third-party cookies can be found in the above table.</p>
			<h3>Managing Cookies</h3>
			<p>Most browsers will automatically accept cookies. No non-neccessary cookies will be stored until the user states their consent via the cookie dialog provided by CookieFirst.  A user may prevent cookies from being stored by changing their browser settings.  Making such changes may limit or prevent user access. Unless you have altered your settings our website will automatically create cookies when you access our website.   By using our website, you agree that we can place these types of cookies on your device. </p>
			<p>You can choose which functional and performance cookies we can set by clicking on the button(s):</p>
			<p>Functional cookies    OFF</p>
			<p>Performance  cookies    OFF</p>
			<p>However, if you use your browser settings to block all cookies (including necessary cookies) you may not be able to access all or parts of our website.</p>
			<p>You can see the consents stored about you by clicking the "Manage Cookie Consent" link on the footer. You can withdraw your consent at any time via the "My Data" section in the pop up panel</p>
			<h3>Changes to Cookie Policy</h3>
			<p>We reserve the right to amend this policy at any time.</p>
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default CookiePolicy;
